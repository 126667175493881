import React, { useState, useRef, useEffect } from "react";
import styles from "./NavigationBar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import NavigationPopup from "components/NavigationPopup/NavigationPopup";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

interface NavigationBarProps {}

export default function NavigationBar({}: NavigationBarProps) {
  const [openNavigation, setOpenNavigation] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setOpenNavigation(false);
    }
  };

  useEffect(() => {
    if (openNavigation) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openNavigation]);

  return (
    <div className={styles.navbarWrapper}>
      {openNavigation && <div className={styles.activNavBackground}></div>}
      <div
        className={styles.logo}
        onClick={(event) => {
          event.stopPropagation();
          setOpenNavigation(!openNavigation);
          navigate("/");
        }}
      >
        <img src="/logo.png" />
      </div>

      <div className={styles.menu}>
        <div
          className={cn(styles.iconWrapper, {
            [styles.searchOpen]: openSearch,
          })}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className={styles.icon}
            onClick={() => setOpenSearch(!openSearch)}
          />
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
          />
        </div>

        {openNavigation ? (
          <div
            ref={modalRef}
            className={styles.navigationWrapper}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <NavigationPopup setOpenNavigation={setOpenNavigation} />
          </div>
        ) : (
          <div className={styles.iconWrapper}>
            <FontAwesomeIcon
              className={styles.icon}
              icon={faBars}
              onClick={() => setOpenNavigation(!openNavigation)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
