import React, { useState } from "react";
import { AppBar, Toolbar } from "@mui/material";
import Layout from "../../components/Layout/Layout.tsx";
import styles from "../CreateDictionary/CreateDictionary.module.scss";
import Box from "components/Box/Box.tsx";
import { useForm, SubmitHandler } from "react-hook-form";
import Loader from "components/Loader/Loader.tsx";
import SucceedMessage from "components/SucceedMessage/SucceedMessage.tsx";
import { RedirectEnum, SuccesBoxesEnum } from "types/enums.ts";

type Inputs = {
  lessonName: string;
  firstLanguage: string;
  secondLanguage: string;
  levelLanguage: string;
};

export default function Settings() {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsloading(true);
      const response = await fetch(
        "https://your-api-endpoint.com/dictionaries",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        setIsloading(false);

        throw new Error(`Error: ${response.statusText}`);
      }
      setIsloading(false);
      const result = await response.json();
    } catch (error: any) {
      setIsloading(false);
      setErrorMsg(error);
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <Box title="Настройки">
        {!isLoading && !isError ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formWrapper}
          >
            <div className={styles.formWithoutButton}>
              <div className={styles.labelAndInput}>
                <label htmlFor="lessonName">Име на урока:</label>
                <input
                  id="lessonName"
                  {...register("lessonName", { required: true })}
                />
                {errors.lessonName && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="levelLanguage">Избери речник:</label>
                <select
                  className={styles.selectLesson}
                  id="levelLanguage"
                  {...register("levelLanguage", { required: true })}
                >
                  <option value="a1">A1</option>
                  <option value="a2">A2</option>
                  <option value="b1">B1</option>
                  <option value="b2">B2</option>
                  <option value="c1">C1</option>
                  <option value="c2">C2</option>
                </select>
                {errors.levelLanguage && <span>Полето е задължително!</span>}
              </div>
            </div>
            <input className={styles.submitBtn} type="submit" value="СЪЗДАЙ" />
          </form>
        ) : isLoading ? (
          <Loader></Loader>
        ) : (
          <SucceedMessage
            boxtype={SuccesBoxesEnum.Lesson}
            redirect={RedirectEnum.Dashboard}
          />
        )}
      </Box>
    </Layout>
  );
}
