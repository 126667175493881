import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RedirectEnum } from "types/enums";
import { Dictionary } from "types/interfaces";

const useGetAllDictionaries = (): UseQueryResult<Dictionary[], Error> => {
  const navigate = useNavigate();
  const getDictionaries = async (): Promise<Dictionary[]> => {
    const email = localStorage.getItem("email");
    const accessToken = localStorage.getItem("accessToken");

    if (!email) {
      throw new Error("Email is not available in local storage");
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_CREATE_DICTIONARY}?email=${email}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
      }

      return response.data;
    } catch (error: any) {
      // need to set a screen with message for expired token and need of new log in
      if (error.status === 403) {
        navigate(RedirectEnum.SignIn);
      }

      if (error.response && error.response.data.accessToken) {
        localStorage.setItem("accessToken", error.response.data.accessToken);
      }
      throw error;
    }
  };

  const queryOptions: UseQueryOptions<Dictionary[]> = {
    queryKey: ["dictionaries"],
    queryFn: getDictionaries,
  };

  return useQuery<Dictionary[]>(queryOptions);
};

export default useGetAllDictionaries;
