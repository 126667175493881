import React, { useState, useEffect } from "react";
import styles from "./FailureMessage.module.scss";
import { FailureMessageProps } from "types/interfaces.ts";
import { FailedBoxesEnum, FailedBoxesType, SuccesBoxesEnum } from "types/enums";
import Button from "components/Button/Button";

export default function FailureMessage({
  boxtype,
  redirect,
  errorStatus,
  errorMessage,
}: FailureMessageProps) {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (errorStatus === 409 && boxtype) {
      setText(
        boxtype === FailedBoxesType.Dictionary
          ? FailedBoxesEnum.DictionaryDuplicated
          : boxtype === FailedBoxesType.Lesson
          ? FailedBoxesEnum.LessonDuplicated
          : boxtype === FailedBoxesType.Word
          ? FailedBoxesEnum.WordDuplicated
          : boxtype === FailedBoxesType.Expression
          ? FailedBoxesEnum.ExpressionDuplicated
          : boxtype === FailedBoxesType.WordExists
          ? FailedBoxesEnum.WordDuplicated
          : ""
      );
    } else if (errorMessage) {
      setText(errorMessage);
    }
  }, [boxtype, errorStatus]);

  return (
    <div className={styles.succeedWrapper}>
      <div className={styles.emptyDiv}></div>
      <div className={styles.succeedText}>{text}</div>

      <Button text={"Напред"} timer={true} redirect={redirect} />
    </div>
  );
}
