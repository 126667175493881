import React, { useState } from "react";
import styles from "../CreateDictionary/CreateDictionary.module.scss";
import Box from "components/Box/Box.tsx";
import { useForm, SubmitHandler } from "react-hook-form";
import Loader from "components/Loader/Loader.tsx";
import SucceedMessage from "components/SucceedMessage/SucceedMessage.tsx";
import { RedirectEnum, SuccesBoxesEnum } from "types/enums.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import cn from "classnames";
import Layout from "components/Layout/Layout";

type Inputs = {
  newWord: string;
  firstLanguage: string;
  isVerb: boolean;
  levelLanguage: string;
  dictionary: string;
  lesson: string;
  plural: string;
};

export default function AddExpressionToLesson() {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const isVerbValue = watch("isVerb");
  const newWordValue = watch("newWord") || ""; // Get the current value of the "newWord" field

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!data.isVerb) {
      data.plural = "";
    }

    try {
      setIsloading(true);
      const response = await fetch(
        "https://your-api-endpoint.com/dictionaries",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        setIsloading(false);

        throw new Error(`Error: ${response.statusText}`);
      }
      setIsloading(false);
      const result = await response.json();
    } catch (error: any) {
      setIsloading(false);
      setErrorMsg(error);
      console.error("Error:", error);
    }
  };

  const addLetterToInput = (letter: string) => {
    // Append the clicked letter to the current input value
    setValue("newWord", newWordValue + letter);
  };

  return (
    <Layout>
      <Box title="Нов израз">
        {!isLoading && !isError ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formWrapper}
          >
            <div className={styles.formWithoutButton}>
              <div className={styles.labelAndInput}>
                <label htmlFor="newWord">
                  Нов израз:
                  <div className={styles.lettersHelper}>
                    {upperCase ? (
                      <>
                        <div onClick={() => addLetterToInput("Ä")}>Ä</div>
                        <div onClick={() => addLetterToInput("Ö")}>Ö</div>
                        <div onClick={() => addLetterToInput("Ü")}>Ü</div>
                        <div onClick={() => addLetterToInput("ẞ")}>ẞ</div>
                        <button
                          type="button"
                          onClick={() => setUpperCase(false)}
                        >
                          <FontAwesomeIcon icon={faCaretDown} />
                        </button>
                      </>
                    ) : (
                      <>
                        <div onClick={() => addLetterToInput("ä")}>ä</div>
                        <div onClick={() => addLetterToInput("ö")}>ö</div>
                        <div onClick={() => addLetterToInput("ü")}>ü</div>
                        <div onClick={() => addLetterToInput("ß")}>ß</div>
                        <button
                          type="button"
                          onClick={() => setUpperCase(true)}
                        >
                          <FontAwesomeIcon icon={faCaretUp} />
                        </button>
                      </>
                    )}
                  </div>
                </label>
                <input
                  id="newWord"
                  {...register("newWord", { required: true })}
                />
                {errors.newWord && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="firstLanguage">Превод:</label>
                <input
                  id="firstLanguage"
                  {...register("firstLanguage", { required: true })}
                />
                {errors.firstLanguage && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="dictionary">Речник:</label>
                <select
                  className={styles.selectLesson}
                  id="dictionary"
                  {...register("dictionary", { required: true })}
                >
                  <option value="a1">A1</option>
                  <option value="a2">A2</option>
                  <option value="b1">B1</option>
                  <option value="b2">B2</option>
                  <option value="c1">C1</option>
                  <option value="c2">C2</option>
                </select>
                {errors.dictionary && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="lesson">Урок:</label>
                <select
                  className={styles.selectLesson}
                  id="lesson"
                  {...register("lesson", { required: true })}
                >
                  <option value="a1">A1</option>
                  <option value="a2">A2</option>
                  <option value="b1">B1</option>
                  <option value="b2">B2</option>
                  <option value="c1">C1</option>
                  <option value="c2">C2</option>
                </select>
                {errors.lesson && <span>Полето е задължително!</span>}
              </div>
            </div>
            <input className={styles.submitBtn} type="submit" value="СЪЗДАЙ" />
          </form>
        ) : isLoading ? (
          <Loader></Loader>
        ) : (
          <SucceedMessage
            boxtype={SuccesBoxesEnum.Dictionary}
            redirect={RedirectEnum.Dashboard}
          />
        )}
      </Box>
    </Layout>
  );
}
