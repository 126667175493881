import React, { useState } from "react"; // Import useState
import styles from "./CreateDictionary.module.scss";
import Box from "components/Box/Box.tsx";
import { useForm, SubmitHandler } from "react-hook-form";
import Loader from "components/Loader/Loader.tsx";
import SucceedMessage from "components/SucceedMessage/SucceedMessage.tsx";
import { FailedBoxesEnum, RedirectEnum, SuccesBoxesEnum } from "types/enums.ts";
import useCreateDictionary from "hooks/useCreateDictionary.ts";
import { AxiosError } from "axios";
import Layout from "components/Layout/Layout";
import FailureMessage from "components/FailureMessage/FailureMessage";
import { CustomError, ErrorResponse, Inputs } from "types/interfaces";

export default function CreateDictionary() {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const {
    mutate: createDictionary,
    isPending,
    isError,
    data: createDictionaryData,
    error,
  } = useCreateDictionary();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    createDictionary(
      {
        dictionaryName: data.dictionaryName,
        dictionaryFirstLang: data.dictionaryFirstLang,
        dictionarySecondLang: data.dictionarySecondLang,
        langLevel: data.levelLanguage,
      },
      {
        onSuccess: (response) => {
          if (response?.created) {
            setIsSuccessful(true);
          }
        },
        onError: (error: CustomError) => {
          console.error("Error submitting lesson:", error);
        },
      }
    );
  };

  return (
    <Layout>
      <Box title="Нов речник">
        {!isPending && !isError && !isSuccessful ? ( // Check for successful state
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formWrapper}
          >
            <div className={styles.formWithoutButton}>
              <div className={styles.labelAndInput}>
                <label htmlFor="dictionaryName">Име на речника:</label>
                <input
                  id="dictionaryName"
                  {...register("dictionaryName", { required: true })}
                />
                {errors.dictionaryName && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="dictionaryFirstLang">
                  Език, на който ще учите:
                </label>
                <input
                  id="dictionaryFirstLang"
                  {...register("dictionaryFirstLang", { required: true })}
                />
                {errors.dictionaryFirstLang && (
                  <span>Полето е задължително!</span>
                )}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="dictionarySecondLang">
                  Език, който искате да изучавате:
                </label>
                <input
                  id="dictionarySecondLang"
                  {...register("dictionarySecondLang", { required: true })}
                />
                {errors.dictionarySecondLang && (
                  <span>Полето е задължително!</span>
                )}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="levelLanguage">Ниво на език в момента:</label>
                <select
                  id="levelLanguage"
                  {...register("levelLanguage", { required: true })}
                >
                  <option value="a1">A1</option>
                  <option value="a2">A2</option>
                  <option value="b1">B1</option>
                  <option value="b2">B2</option>
                  <option value="c1">C1</option>
                  <option value="c2">C2</option>
                </select>
                {errors.levelLanguage && <span>Полето е задължително!</span>}
              </div>
            </div>
            <input className={styles.submitBtn} type="submit" value="СЪЗДАЙ" />
          </form>
        ) : isPending ? (
          <Loader />
        ) : isError ? (
          <SucceedMessage
            boxtype={
              (error as AxiosError<ErrorResponse>).status === 409
                ? FailedBoxesEnum.DictionaryDuplicated
                : "Error occurred"
            }
            redirect={RedirectEnum.Dashboard}
          />
        ) : isSuccessful ? ( // Check for success state to show message
          <SucceedMessage
            boxtype={SuccesBoxesEnum.Dictionary}
            redirect={RedirectEnum.Dashboard}
          />
        ) : error ? (
          <>
            <FailureMessage
              errorStatus={(error as CustomError)?.status}
              boxtype={FailedBoxesEnum.DictionaryDuplicated}
              redirect={RedirectEnum.Dashboard}
              errorMessage={(error as CustomError).message}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
    </Layout>
  );
}
