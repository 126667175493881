import React, { useState, useEffect } from "react";
import styles from "./Button.module.scss";
import { ButtonProps } from "types/interfaces.ts";
import { useNavigate } from "react-router-dom";

export default function Button({ text, timer, redirect }: ButtonProps) {
  const navigate = useNavigate();
  const [secondsCountDown, setSecondsCountDown] = useState<number>(10);

  useEffect(() => {
    if (timer) {
      const interval = setInterval(() => {
        setSecondsCountDown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            navigate(redirect);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, navigate, redirect]);

  return (
    <div className={styles.buttonWrapper}>
      <button className={styles.button} onClick={() => navigate(redirect)}>
        {text} {timer && secondsCountDown > 0 && `(${secondsCountDown})`}
      </button>
    </div>
  );
}
