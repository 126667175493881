import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  useQueryClient,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import { RedirectEnum } from "types/enums";
import { CreateDictionaryProps } from "types/interfaces";

const useCreateDictionary = (): UseMutationResult<
  any,
  Error,
  CreateDictionaryProps,
  unknown
> => {
  const storedEmail = localStorage.getItem("email");
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const createDictionary = async (newDictionary: CreateDictionaryProps) => {
    let accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        process.env.REACT_APP_URL_CREATE_DICTIONARY!,
        {
          email: storedEmail,
          ...newDictionary,
          userTimezone,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.accessToken) {
        localStorage.setItem("accessToken", error.response.data.accessToken);
      }
      throw error;
    }
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDictionary,
    onSuccess: () => {
      queryClient.invalidateQueries({});
    },
  });
};

export default useCreateDictionary;
