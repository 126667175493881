import { create } from "zustand";

interface AccountStore {
  email: string;
  setEmail: (email: string) => void;
  resetStore: () => void;
}

const initialValues = {
  email: "",
};

export const useAccountStore = create<AccountStore>((set) => ({
  ...initialValues,
  setEmail: (email) => set((state) => ({ email })),
  resetStore: () => set((state) => ({ ...initialValues })),
}));
