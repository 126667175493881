import React, { useEffect } from "react";
import styles from "./Dashboard.module.scss";
import { useNavigate } from "react-router-dom";
import { RedirectEnum } from "types/enums.ts";
import Layout from "components/Layout/Layout";
import useGetAllDictionaries from "hooks/useGetAllDictionaries";

export default function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate(RedirectEnum.SignIn);
    }
  }, [localStorage]);

  return (
    <Layout>
      <div className={styles.createDictionaryWrapper}>Dashboard</div>
    </Layout>
  );
}
