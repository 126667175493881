import axios from "axios";
import {
  useQueryClient,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import { CreateWordProps } from "types/interfaces";

const useCreateWord = (): UseMutationResult<
  any,
  Error,
  CreateWordProps,
  unknown
> => {
  const storedEmail = localStorage.getItem("email");

  const createWord = async (newWord: CreateWordProps) => {
    let accessToken = localStorage.getItem("accessToken");
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const response = await axios.post(
        process.env.REACT_APP_URL_CREATE_WORD!,
        {
          email: storedEmail,
          ...newWord,
          userTimezone,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.accessToken) {
        localStorage.setItem("accessToken", error.response.data.accessToken);
      }

      throw error;
    }
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createWord,
    onSuccess: () => {
      queryClient.invalidateQueries({});
    },
  });
};

export default useCreateWord;
