import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.scss";
import Layout from "components/Layout/Layout";
import Box from "components/Box/Box";
import Button from "components/Button/Button";

export default function NotFound() {
  return (
    <Layout>
      <Box>
        <div className={styles.container}>
          <h1 className={styles.header}>404 - Page Not Found</h1>
          <p className={styles.text}>
            Oops! The page you are looking for does not exist.
          </p>
          <Button text={"Home page"} redirect="/" />
        </div>
      </Box>
    </Layout>
  );
}
