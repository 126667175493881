import { useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RedirectEnum } from "types/enums";
import { useAccountStore } from "../stores/useAccountStore";

const useLogOut = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const emailFromStore = useAccountStore((state) => state.email);
  const resetStore = useAccountStore((state) => state.resetStore);

  const logOut = useCallback(async () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("No access token found");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("email");

      resetStore();
      navigate(RedirectEnum.SignIn);
      return false;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_URL_LOGOUT!,
        { email },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("email");

        resetStore();
        navigate(RedirectEnum.SignIn);
        return true;
      }
    } catch (error) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("email");
      navigate(RedirectEnum.SignIn);

      return error;
    }

    return false;
  }, [email, navigate]);

  return logOut;
};

export default useLogOut;
