import * as React from "react";
import NavigationBar from "../NavigationBar/NavigationBar.tsx";

interface Props {
  children?: React.ReactNode | null; // Allow null here
}

export default function Layout({ children }: Props) {
  return (
    <div>
      <NavigationBar />
      {children}
    </div>
  );
}
