import React, { useState, useEffect } from "react";
import styles from "./SucceedMessage.module.scss";
import { SucceedMessageProps } from "types/interfaces.ts";
import { SuccesBoxesEnum } from "types/enums";
import Button from "components/Button/Button";

export default function SucceedMessage({
  boxtype,
  redirect,
}: SucceedMessageProps) {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setText(boxtype);
  }, [boxtype]);

  return (
    <div className={styles.succeedWrapper}>
      <div className={styles.emptyDiv}></div>
      <div className={styles.succeedText}>{text}</div>

      <Button text={"Напред"} timer={true} redirect={redirect} />
    </div>
  );
}
