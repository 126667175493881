import React, { useState } from "react";
import Layout from "../../components/Layout/Layout.tsx";
import Box from "components/Box/Box.tsx";
import { useForm, SubmitHandler } from "react-hook-form";
import Loader from "components/Loader/Loader.tsx";
import SucceedMessage from "components/SucceedMessage/SucceedMessage.tsx";
import { FailedBoxesType, RedirectEnum, SuccesBoxesEnum } from "types/enums.ts";
import { useQueryClient } from "@tanstack/react-query";
import {
  CachedDictionaries,
  CustomError,
  InputsDictionary,
  InputsLesson,
} from "types/interfaces.ts";
import useCreateDictionary from "hooks/useCreateDictionary.ts";
import useCreateLesson from "hooks/useCreateLesson.ts";
import cn from "classnames";
import styles from "./CreateLesson.module.scss";
import FailureMessage from "components/FailureMessage/FailureMessage.tsx";

export default function CreateLesson() {
  const [isNewDictionary, setIsNewDictionary] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const queryClient = useQueryClient();

  const {
    mutate: createDictionary,
    isPending: isPendingDictionary,
    isError: isErrorDictionary,
    error: errorDictionary,
  } = useCreateDictionary();

  const {
    mutate: createLesson,
    isPending: isPendingLesson,
    isError: isErrorLesson,
    error: errorLesson,
  } = useCreateLesson();

  const cachedDictionaries = queryClient.getQueryData<CachedDictionaries>([
    "dictionaries",
  ]);

  const {
    register: registerLesson,
    handleSubmit: handleSubmitLesson,
    watch: watchLesson,
    formState: { errors: errorsLesson },
  } = useForm<InputsLesson>();

  const {
    register: registerDictionary,
    watch: watchDictionary,
    handleSubmit: handleSubmitDictionary,
    formState: { errors: errorsDictionary },
  } = useForm<InputsDictionary>();

  const onSubmitLesson: SubmitHandler<InputsLesson> = (data) => {
    createLesson(data, {
      onSuccess: (response) => {
        if (response?.created) {
          setIsSuccessful(true);
        }
      },
      onError: (error: CustomError) => {
        console.error("Error submitting lesson:", error);
      },
    });
  };

  const onSubmitDictionary: SubmitHandler<InputsDictionary> = async (data) => {
    try {
      await createDictionary({
        dictionaryName: data.dictionaryName,
        dictionaryFirstLang: data.dictionaryFirstLang,
        dictionarySecondLang: data.dictionarySecondLang,
        langLevel: data.levelLanguage,
      });
    } catch (err) {
      console.error("Failed to create dictionary:", err);
    }
  };

  const handleNewDictionaryToggle = () => {
    setIsNewDictionary((prev) => !prev);
  };

  const isPending = [isPendingLesson, isPendingDictionary].some(Boolean);
  const isError = [isErrorLesson, isErrorDictionary].some(Boolean);
  const error = errorDictionary || errorLesson;

  // Using watch functions to retrieve current values
  const watchedLesson = watchLesson();
  const watchedDictionary = watchDictionary();

  let isDisabled = isNewDictionary
    ? !(
        watchedDictionary.dictionaryName?.trim().length > 0 &&
        watchedDictionary.dictionaryFirstLang?.trim().length > 0 &&
        watchedDictionary.dictionarySecondLang?.trim().length > 0 &&
        watchedDictionary.levelLanguage?.trim().length > 0 &&
        watchedLesson.lessonName?.trim().length > 0
      )
    : !(
        watchedLesson.lessonName?.trim().length > 0 &&
        watchedLesson.dictionaryName?.trim().length > 0
      );

  const onSubmit = async (data: InputsLesson) => {
    if (isNewDictionary) {
      // Call the dictionary submit function first
      const dictData: InputsDictionary = {
        dictionaryName: watchedDictionary.dictionaryName,
        dictionaryFirstLang: watchedDictionary.dictionaryFirstLang,
        dictionarySecondLang: watchedDictionary.dictionarySecondLang,
        levelLanguage: watchedDictionary.levelLanguage,
      };
      await onSubmitDictionary(dictData);
    }
    await onSubmitLesson(data);
  };

  return (
    <Layout>
      <Box title="Нов урок">
        {!isPending && !isError && !isSuccessful ? (
          <form
            className={styles.formWrapper}
            onSubmit={handleSubmitLesson(onSubmit)}
          >
            <div className={styles.formWithoutButton}>
              <div className={styles.labelAndInput}>
                <label htmlFor="lessonName">Име на урока:</label>
                <input
                  id="lessonName"
                  {...registerLesson("lessonName", { required: true })}
                />
                {errorsLesson.lessonName && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <div className={styles.checkBoxWraper}>
                  <label htmlFor="newDictionary">Нов речник:</label>
                  <input
                    className={styles.checkBox}
                    id="newDictionary"
                    type="checkbox"
                    checked={isNewDictionary}
                    onChange={handleNewDictionaryToggle}
                  />
                </div>
                <label htmlFor="dictionaryName">Избери речник:</label>
                <select
                  disabled={isNewDictionary} // Enable if new dictionary is checked
                  className={styles.selectLesson}
                  id="dictionaryName"
                  {...registerLesson("dictionaryName", { required: true })}
                >
                  {cachedDictionaries &&
                    cachedDictionaries.dictionaries &&
                    cachedDictionaries.dictionaries.map((dictionary) => (
                      <option
                        key={dictionary.dictionaryName}
                        value={dictionary.dictionaryName}
                      >
                        {dictionary.dictionaryName}
                      </option>
                    ))}
                </select>
                {errorsLesson.dictionaryName && (
                  <span>Полето е задължително!</span>
                )}
              </div>
              {isNewDictionary && (
                <div className={styles.newDictionaryFields}>
                  <div className={styles.labelAndInput}>
                    <label htmlFor="dictionaryNameNew">Име на речника:</label>
                    <input
                      id="dictionaryNameNew"
                      {...registerDictionary("dictionaryName", {
                        required: true,
                      })}
                    />
                    {errorsDictionary.dictionaryName && (
                      <span>Полето е задължително!</span>
                    )}
                  </div>
                  <div className={styles.labelAndInput}>
                    <label htmlFor="dictionaryFirstLang">
                      Език, на който ще учите:
                    </label>
                    <input
                      id="dictionaryFirstLang"
                      {...registerDictionary("dictionaryFirstLang", {
                        required: true,
                      })}
                    />
                    {errorsDictionary.dictionaryFirstLang && (
                      <span>Полето е задължително!</span>
                    )}
                  </div>
                  <div className={styles.labelAndInput}>
                    <label htmlFor="dictionarySecondLang">
                      Език, който искате да изучавате:
                    </label>
                    <input
                      id="dictionarySecondLang"
                      {...registerDictionary("dictionarySecondLang", {
                        required: true,
                      })}
                    />
                    {errorsDictionary.dictionarySecondLang && (
                      <span>Полето е задължително!</span>
                    )}
                  </div>
                  <div className={styles.labelAndInput}>
                    <label htmlFor="levelLanguage">
                      Ниво на език в момента:
                    </label>
                    <select
                      id="levelLanguage"
                      {...registerDictionary("levelLanguage", {
                        required: true,
                      })}
                    >
                      <option value="a1">A1</option>
                      <option value="a2">A2</option>
                      <option value="b1">B1</option>
                      <option value="b2">B2</option>
                      <option value="c1">C1</option>
                      <option value="c2">C2</option>
                    </select>
                    {errorsDictionary.levelLanguage && (
                      <span>Полето е задължително!</span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <input
              className={
                isDisabled
                  ? cn(styles.submitBtn, styles.submitBtnDisabled)
                  : styles.submitBtn
              }
              type="submit"
              value="СЪЗДАЙ"
              disabled={isDisabled}
            />
          </form>
        ) : isPending ? (
          <Loader />
        ) : isSuccessful ? (
          <SucceedMessage
            boxtype={SuccesBoxesEnum.Lesson}
            redirect={RedirectEnum.Dashboard}
          />
        ) : error ? (
          <FailureMessage
            errorStatus={(error as CustomError)?.status}
            boxtype={FailedBoxesType.Lesson}
            redirect={RedirectEnum.Dashboard}
            errorMessage={error.message}
          />
        ) : null}
      </Box>
    </Layout>
  );
}
