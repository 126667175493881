import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import Layout from "components/Layout/Layout";

export default function LearnExpressions() {
  return (
    <Layout>
      <div>LearnExpressions</div>
    </Layout>
  );
}
