import React, { useState } from "react";
import styles from "../AddWordToLesson/AddWordToLesson.module.scss";
import Box from "components/Box/Box.tsx";
import { useForm, SubmitHandler } from "react-hook-form";
import Loader from "components/Loader/Loader.tsx";
import SucceedMessage from "components/SucceedMessage/SucceedMessage.tsx";
import {
  FailedBoxesEnum,
  FailedBoxesType,
  RedirectEnum,
  SuccesBoxesEnum,
} from "types/enums.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Layout from "components/Layout/Layout";
import cn from "classnames";
import {
  CachedDictionaries,
  CachedLessons,
  CustomError,
  Dictionary,
  InputWords,
  Lesson,
} from "types/interfaces";
import { useQueryClient } from "@tanstack/react-query";
import FailureMessage from "components/FailureMessage/FailureMessage";
import useCreateWord from "hooks/useCreateWord";

export default function AddWordToLesson() {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const queryClient = useQueryClient();

  const cachedDictionaries = queryClient.getQueryData<CachedDictionaries>([
    "dictionaries",
  ]);
  const cachedLessons = queryClient.getQueryData<CachedLessons>(["lessons"]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<InputWords>();

  const {
    mutate: createWord,
    isPending,
    isError: isErrorWord,
    data: createWordData,
    error: errorWord,
  } = useCreateWord();

  const isNoun = watch("isNoun");
  const newWord = watch("newWord") || "";
  const translationWord = watch("translationWord") || "";
  const dictionary = watch("dictionary") || "";
  const lesson = watch("lesson") || "";
  const nounPlural = watch("nounPlural") || "";
  const nounGender = watch("nounGender") || "";

  // Define base condition for non-noun fields
  const baseCondition =
    !!newWord && !!translationWord && !!dictionary && !!lesson;

  // Extend condition if `isNoun` is true
  const isDisabled = isNoun
    ? !!baseCondition && !!nounPlural && !!nounGender
    : !!baseCondition;

  const onSubmit: SubmitHandler<InputWords> = async (data) => {
    if (!data.isNoun) {
      data.nounPlural = "";
    }

    createWord(
      {
        dictionary: data.dictionary,
        translationWord: data.translationWord,
        newWord: data.newWord,
        isNoun: data.isNoun,
        nounGender: data.nounGender,
        nounPlural: data.nounPlural,
        lesson: data.lesson,
      },
      {
        onSuccess: (response) => {
          if (response?.created) {
            setIsSuccessful(true);
          }
        },
        onError: (error: CustomError) => {
          console.error("Error submitting lesson:", error);
        },
      }
    );
  };

  const addLetterToInput = (letter: string) => {
    // Append the clicked letter to the current input value
    setValue("newWord", newWord + letter);
  };

  if (
    !cachedDictionaries?.dictionaries.length ||
    !cachedLessons?.lessons.length
  ) {
    return (
      <Layout>
        <Box>
          <FailureMessage
            boxtype={FailedBoxesType.NoLesson}
            redirect={RedirectEnum.CreateLesson}
            errorMessage={FailedBoxesEnum.NoLesson}
          />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box title="Нова дума">
        {!isPending && !isErrorWord && !isSuccessful ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formWrapper}
          >
            <div className={styles.formWithoutButton}>
              <div className={styles.labelAndInput}>
                <label htmlFor="newWord">
                  Нова дума:
                  <div className={styles.lettersHelper}>
                    {upperCase ? (
                      <>
                        <div onClick={() => addLetterToInput("Ä")}>Ä</div>
                        <div onClick={() => addLetterToInput("Ö")}>Ö</div>
                        <div onClick={() => addLetterToInput("Ü")}>Ü</div>
                        <div onClick={() => addLetterToInput("ẞ")}>ẞ</div>
                        <button
                          type="button"
                          onClick={() => setUpperCase(false)}
                        >
                          <FontAwesomeIcon icon={faCaretDown} />
                        </button>
                      </>
                    ) : (
                      <>
                        <div onClick={() => addLetterToInput("ä")}>ä</div>
                        <div onClick={() => addLetterToInput("ö")}>ö</div>
                        <div onClick={() => addLetterToInput("ü")}>ü</div>
                        <div onClick={() => addLetterToInput("ß")}>ß</div>
                        <button
                          type="button"
                          onClick={() => setUpperCase(true)}
                        >
                          <FontAwesomeIcon icon={faCaretUp} />
                        </button>
                      </>
                    )}
                  </div>
                </label>
                <input
                  id="newWord"
                  {...register("newWord", { required: true })}
                />
                {errors.newWord && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="translationWord">Превод:</label>
                <input
                  id="translationWord"
                  {...register("translationWord", { required: true })}
                />
                {errors.translationWord && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="dictionary">Речник:</label>
                <select
                  className={styles.selectLesson}
                  id="dictionary"
                  {...register("dictionary", { required: true })}
                >
                  {cachedDictionaries && cachedDictionaries.dictionaries
                    ? cachedDictionaries.dictionaries.map(
                        (dictionary: Dictionary) => {
                          return (
                            <option
                              key={`${dictionary.dictionaryName}`}
                              value={`${dictionary.dictionaryName}`}
                            >
                              {dictionary.dictionaryName}
                            </option>
                          );
                        }
                      )
                    : null}
                </select>
                {errors.dictionary && <span>Полето е задължително!</span>}
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="lesson">Урок:</label>
                <select
                  className={styles.selectLesson}
                  id="lesson"
                  {...register("lesson", { required: true })}
                >
                  {cachedLessons?.lessons?.map((lesson: Lesson) => (
                    <option key={lesson.lessonName} value={lesson.lessonName}>
                      {lesson.lessonName}
                    </option>
                  )) || null}
                </select>
                {errors.lesson && <span>Полето е задължително!</span>}
              </div>

              <div
                className={
                  isNoun
                    ? cn(styles.labelAndRadio, styles.newField)
                    : cn(styles.labelAndRadio, styles.noNewField)
                }
              >
                <label htmlFor="isNoun">Съществително име:</label>
                <input type="checkbox" id="isNoun" {...register("isNoun")} />
              </div>
              {isNoun && (
                <div className={styles.labelAndInput}>
                  <label htmlFor="nounPlural">Множествено число:</label>
                  <input
                    id="nounPlural"
                    {...register("nounPlural", { required: isNoun })}
                  />
                  {errors.nounPlural && <span>Полето е задължително!</span>}
                </div>
              )}
              {isNoun && (
                <div className={styles.labelAndInput}>
                  <label htmlFor="nounGender">Определителен член:</label>
                  <input
                    id="nounGender"
                    {...register("nounGender", { required: isNoun })}
                  />
                  {errors.nounGender && <span>Полето е задължително!</span>}
                </div>
              )}
            </div>
            <input
              className={
                !isDisabled
                  ? cn(styles.submitBtn, styles.submitBtnDisabled)
                  : styles.submitBtn
              }
              type="submit"
              value="СЪЗДАЙ"
              disabled={!isDisabled}
            />
          </form>
        ) : isPending ? (
          <Loader></Loader>
        ) : isSuccessful ? (
          <SucceedMessage
            boxtype={SuccesBoxesEnum.Dictionary}
            redirect={RedirectEnum.Dashboard}
          />
        ) : isErrorWord ? (
          <FailureMessage
            errorStatus={(errorWord as CustomError)?.status}
            boxtype={
              (errorWord as CustomError) &&
              (errorWord as CustomError)?.status === 409
                ? FailedBoxesType.WordExists
                : FailedBoxesType.NoLesson
            }
            redirect={RedirectEnum.Dashboard}
            errorMessage={errorWord.message}
          />
        ) : null}
      </Box>
    </Layout>
  );
}
