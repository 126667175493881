import React, { useState, useRef, useEffect } from "react";
import styles from "./NavigationPopup.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faAddressBook,
  faPencil,
  faPenFancy,
  faScroll,
  faGraduationCap,
  faChalkboardTeacher,
  faXmark,
  faGear,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import cn from "classnames";
import { RedirectEnum } from "types/enums";
import useLogOut from "hooks/useLogOut";

interface NavigationPopupProps {
  setOpenNavigation: (f: boolean) => void;
}

export default function NavigationPopup({
  setOpenNavigation,
}: NavigationPopupProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const email = localStorage.getItem("email");
  const path = location.pathname;
  const logOut = useLogOut();

  return (
    <div className={styles.navbarPopupWrapper}>
      <div className={styles.profileWrapper}>
        <div
          className={cn(styles.profileIcon, styles.hoverEffect)}
          onClick={() => {
            setOpenNavigation(false);
            navigate(RedirectEnum.Dashboard);
          }}
        >
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div
          className={cn(styles.profileText, styles.hoverEffect)}
          onClick={() => navigate(RedirectEnum.Dashboard)}
        >
          Здравей, Марио
        </div>
        <div
          className={styles.close}
          onClick={() => {
            setOpenNavigation(false);
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>

      <div className={styles.itemsWrapper}>
        <div
          className={
            path === RedirectEnum.CreateDictionary
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={() => {
            navigate(RedirectEnum.CreateDictionary);
            setOpenNavigation(false);
          }}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faAddressBook} />
          </div>
          <div className={styles.itemText}>Създай речник</div>
        </div>
        <div
          className={
            path === RedirectEnum.CreateLesson
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={() => {
            navigate(RedirectEnum.CreateLesson);
            setOpenNavigation(false);
          }}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faChalkboardTeacher} />
          </div>
          <div className={styles.itemText}>Създай урок</div>
        </div>
        <div
          className={
            path === RedirectEnum.AddWordToLesson
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={() => {
            navigate(RedirectEnum.AddWordToLesson);
            setOpenNavigation(false);
          }}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faPencil} />
          </div>
          <div className={styles.itemText}>Добави дума в урок</div>
        </div>
        <div
          className={
            path === RedirectEnum.AddExpressionToLesson
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={() => {
            navigate(RedirectEnum.AddExpressionToLesson);
            setOpenNavigation(false);
          }}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faPenFancy} />
          </div>
          <div className={styles.itemText}>Добави израз в урок</div>
        </div>
        <div
          className={
            path === RedirectEnum.LearnWords
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={() => {
            navigate(RedirectEnum.LearnWords);
            setOpenNavigation(false);
          }}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faGraduationCap} />
          </div>
          <div className={styles.itemText}>Учи думи</div>
        </div>
        <div
          className={
            path === RedirectEnum.LearnExpressions
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={() => {
            navigate(RedirectEnum.LearnExpressions);
            setOpenNavigation(false);
          }}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faScroll} />
          </div>
          <div className={styles.itemText}>Учи изрази</div>
        </div>
        <div
          className={
            path === RedirectEnum.Settings
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={() => {
            navigate(RedirectEnum.Settings);
            setOpenNavigation(false);
          }}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faGear} />
          </div>
          <div className={styles.itemText}>Настройки</div>
        </div>
        <div
          className={
            path === RedirectEnum.LogOut
              ? cn(styles.item, styles.currentPath)
              : styles.item
          }
          onClick={logOut}
        >
          <div className={styles.itemIcon}>
            <FontAwesomeIcon icon={faRightFromBracket} />
          </div>
          <div className={styles.itemText}>Изход</div>
        </div>
      </div>
    </div>
  );
}
