import React from "react";
import styles from "./Box.module.scss";
import { BoxProps } from "types/interfaces.ts";
import cn from "classnames";

export default function Box({ title, children }: BoxProps) {
  return (
    <>
      <div className={styles.pageTitle}>
        <h1>{title}</h1>
      </div>
      <div className={cn(styles.boxWrapper)}>
        <div
          className={cn(
            styles.formWrapper,
            styles.shadowDropCenter,
            styles.flipp
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
}
